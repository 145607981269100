.App {
  text-align: center;
}

* {
  font-family: 'Noto Sans' !important;
  transition-duration: 0.6s;
  transition-delay: 0.1s;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* angled div */
.header {
  position: relative;
  padding: 90px 0 20px 0;
  overflow: visible;
  /* background: #A0D7D0; */
  background: rgba(147, 152, 229, 1);
  /* background: linear-gradient(97.88deg, rgba(97, 102, 179, 0.8) 19.57%, rgba(137, 181, 175, 0.8) 85.97%); */
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 100px 0;
  background: inherit;
  z-index: -1;
  bottom: 0;
  transform-origin: right bottom;
  transform: skewY(-12deg);
}

.App-link {
  color: #61dafb;
}

/* Colors */
.green {
  background: linear-gradient(105deg, #96C7C1 10%, #89B5AF 90%);
}

/* Colors */
.purple {
  background: linear-gradient(105deg, rgba(97, 102, 179, 0.6) 20%, rgba(97, 102, 179, 0.9) 80%);
}

.white {  
  background: linear-gradient(105deg, rgba(255, 255, 255, 0.9) 10%, rgba(255, 255, 255, 1) 90%);
}

.white-text {
	color: #fff;
}

.green-text {
	color: #89B5AF;
}

.purple-text {
	color: rgba(97, 102, 179, 0.9);
}

/* Hover */
.hoverable {
  cursor: pointer;
}

.hoverable:hover {
  opacity: 0.5;
}

/* Align */
.absolute {
  position: absolute;
	z-index: 10;
}

.left-align {
	text-align: left;
}

.right {
  margin-left: auto;
}

.middle {
  margin: auto 0;
}

.no-underline {
  text-decoration: none;
}

.standard-margin {
  width: calc(100% - 120px);
	margin: 10px 60px;
}

@media screen and (max-width: 720px) {
  .standard-margin {
    width: calc(100% - 80px);
    margin: 10px 40px;
  }
}

@media screen and (max-width: 600px) {
  .standard-margin {
    width: calc(100% - 60px);
    margin: 10px 30px;
  }
}

@media screen and (max-width: 400px) {
  .no-mobile {
    display: none;
  }
}